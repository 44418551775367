var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"zt-page-content"},[_c('div',{staticClass:"zt-block search-query"},[_c('div',{staticClass:"clearfix"},[_c('div',{staticClass:"fl flex tab-menu"},_vm._l((_vm.tabList),function(item,index){return _c('div',{key:index,staticClass:"menu-item-button",class:{ cur: _vm.tabIndex == index },on:{"click":function($event){return _vm.tab(index)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0),_c('div',{staticClass:"fr"},[_c('el-row',{attrs:{"type":"flex","gutter":24}},[_c('el-col',[_c('el-row',[_c('el-col',[_c('el-row',{attrs:{"type":"flex","align":"middle"}},[_c('el-col',{staticClass:"label",attrs:{"span":12}},[_vm._v("需求类型")]),_c('el-col',[_c('el-select',{on:{"change":_vm.getdemandType},model:{value:(_vm.demandType),callback:function ($$v) {_vm.demandType=$$v},expression:"demandType"}},_vm._l((_vm.demandTypeOption),function(item){return _c('el-option',{key:item.optionCode,attrs:{"label":item.optionValue,"value":item.optionCode}})}),1)],1)],1)],1)],1)],1),_c('el-col',{staticClass:"search-content"},[_c('el-input',{attrs:{"placeholder":"请输入搜索关键字","suffix-icon":"el-icon-search"},on:{"blur":_vm.getKeyWords},model:{value:(_vm.keywords),callback:function ($$v) {_vm.keywords=$$v},expression:"keywords"}})],1)],1)],1)])]),_c('div',{staticClass:"zt-block",staticStyle:{"height":"calc(100% - 200px)","overflow":"hidden","margin-bottom":"10px"}},[_c('div',{staticClass:"list",staticStyle:{"height":"100%","overflow":"auto"}},_vm._l((_vm.list),function(item,idx){return _c('div',{key:idx,staticClass:"list-item flex",staticStyle:{"min-width":"1330px"}},[_c('div',{staticClass:"flex",staticStyle:{"width":"300px","cursor":"pointer","padding-right":"20px"},on:{"click":function($event){return _vm.viewDetail(item)}}},[_c('div',{staticStyle:{"width":"120px","height":"120px","margin-right":"10px"}},[(
                item[item.type] &&
                item[item.type].productImgs &&
                item[item.type].productImgs.length > 0
              )?_c('div',{staticClass:"bg_img",style:({
                backgroundImage:
                  'url(' +
                  _vm.imgUrl +
                  item[item.type].productImgs[0] +
                  '!width_250px' +
                  ')',
              })}):_vm._e()]),(item.title)?_c('div',{staticClass:"flex-1 flex-justify-between",staticStyle:{"text-align":"left"}},[_c('h3',{staticClass:"font14-grey ellipsis1"},[_vm._v(_vm._s(item.title))]),(item[item.type] && item[item.type].cat)?_c('p',{staticStyle:{"color":"#b3b3b3"}},[_vm._v(" "+_vm._s(item[item.type].cat.name)+" ")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"list-item-tr",staticStyle:{"min-width":"200px","max-width":"350px"}},[_c('div',{staticClass:"font14-grey1"},[_vm._v("需求类型")]),(item.type)?_c('div',[_vm._v(_vm._s(_vm._f("demandFilt")(item.type)))]):_vm._e()]),_c('div',{staticClass:"list-item-tr",staticStyle:{"min-width":"250px","max-width":"350px"}},[_c('div',{staticStyle:{"color":"#b3b3b3"}},[_vm._v("出价预算")]),(item[item.type].prices && item[item.type].prices.length > 0)?_c('div',[(item[item.type].prices[0])?_c('span',[_vm._v("￥"+_vm._s(_vm._f("priceFilt")(item[item.type].prices[0])))]):_vm._e(),(item[item.type].prices[1])?_c('span',[_vm._v("~"+_vm._s(_vm._f("priceFilt")(item[item.type].prices[1])))]):_vm._e()]):(item.offers && item.offers.length > 0)?_c('div',[_vm._v(" ￥"+_vm._s(_vm._f("priceFilt")(item.offers[0][item.type].price))+" ")]):_vm._e()]),_c('div',{staticClass:"list-item-tr",staticStyle:{"width":"180px"}},[_c('div',{staticStyle:{"color":"#b3b3b3"}},[(item.type == 'dayang')?_c('div',[_vm._v("期望验收时间")]):(item.type == 'gaikuang')?_c('div',[_vm._v("期望交货时间")]):_c('div',[_vm._v("开始交货时间")])]),(
              item.type == 'dayang' &&
              item[item.type] &&
              item[item.type].checkDate
            )?_c('div',[_vm._v(" "+_vm._s(_vm._f("moment")(item[item.type].checkDate,"YYYY-MM-DD"))+" ")]):_vm._e(),(
              item.type != 'dayang' &&
              item[item.type] &&
              item[item.type].giveDate
            )?_c('div',[_vm._v(" "+_vm._s(_vm._f("moment")(item[item.type].giveDate,"YYYY-MM-DD"))+" ")]):_vm._e()]),_c('div',{staticClass:"list-item-tr",staticStyle:{"width":"180px"}},[_c('div',{staticStyle:{"color":"#b3b3b3"}},[(item.type == 'peitao')?_c('div',[_vm._v("需求量")]):_vm._e(),(item.type == 'gaikuang')?_c('div',[_vm._v("设计类型")]):_vm._e(),(item.type == 'dayang')?_c('div',[_vm._v("需求量")]):_vm._e()]),_c('div',[(
                item.type == 'peitao' &&
                item[item.type] &&
                item[item.type].count
              )?_c('div',[_vm._v(" "+_vm._s(item[item.type].count)+" "),_c('span',{staticClass:"font14-grey1"},[_vm._v(" 个 "),(item[item.type] && item[item.type].type == 2)?_c('span',[_vm._v("/月")]):_vm._e()])]):_vm._e(),(
                item.type == 'dayang' &&
                item[item.type] &&
                item[item.type].count
              )?_c('div',[_vm._v(" "+_vm._s(item[item.type].count)+" "),_c('span',{staticClass:"font14-grey1"},[_vm._v("个")])]):_vm._e(),(
                item.type == 'gaikuang' &&
                item[item.type] &&
                item[item.type].fileTypes.length > 0
              )?_c('div',_vm._l((item[item.type].fileTypes),function(item1,idx1){return _c('div',{key:idx1},[(item1 == '3d')?_c('div',[_vm._v("3d渲染图")]):_vm._e(),(item1 == 'material')?_c('div',[_vm._v("电商素材")]):_vm._e(),(item1 == 'size')?_c('div',[_vm._v("尺寸图")]):_vm._e()])}),0):_vm._e()])]),_c('div',{staticClass:"list-item-tr flex-1",staticStyle:{"min-width":"150px","padding":"5px 15px"}},[_c('div',[(item.state == 5)?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.republish(item)}}},[_vm._v("重新发起")]):_vm._e(),(item.state == 1)?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.edit(item, _vm.tabIndex)}}},[_vm._v("继续编辑")]):_vm._e(),(item.state == 1)?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.issue(item, _vm.tabIndex)}}},[_vm._v("发布")]):_vm._e(),([2, 3].includes(item.state))?_c('el-button',{attrs:{"size":"mini","type":"primary","plain":"","round":""},on:{"click":function($event){return _vm.close(item)}}},[_vm._v("关闭需求")]):_vm._e(),(item.state == 4)?_c('el-button',{attrs:{"size":"mini","type":"primary","plain":"","round":""},on:{"click":function($event){return _vm.goOrder(item)}}},[_vm._v("查看需求订单")]):_vm._e(),(item.state >= 2 && item.state <= 5)?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.goto(
                  '/want/wantFollow/pipeList/' +
                    item.id +
                    '?type=' +
                    item.type
                )}}},[(['peitao', 'dayang'].includes(item.type))?_c('span',[_vm._v(" 查看工厂应答 "),_c('span',[_vm._v("("+_vm._s(item.offers ? item.offers.length : 0)+")")])]):_vm._e(),(['gaikuang'].includes(item.type))?_c('span',[_vm._v(" 查看设计公司应答 "),_c('span',[_vm._v("("+_vm._s(item.offers ? item.offers.length : 0)+")")])]):_vm._e()]):_vm._e()],1)])])}),0)]),_c('div',{staticStyle:{"padding":"7px"}},[_c('el-pagination',{attrs:{"background":"","layout":"prev, pager, next, total","current-page":_vm.queryOpt.page.pageNumber,"page-size":_vm.queryOpt.page.pageCount,"total":_vm.total},on:{"update:currentPage":function($event){return _vm.$set(_vm.queryOpt.page, "pageNumber", $event)},"update:current-page":function($event){return _vm.$set(_vm.queryOpt.page, "pageNumber", $event)}}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }